'use client';
import { useState } from 'react';
import styles from './button.module.css';

const Button = ({
	onClick,
	variant,
	color,
	size,
	fullWidth,
	iconLeft,
	iconRight,
	children,
	disabled,
	hover = false
}) => {
	const [disable, setDisabled] = useState(false);

	const handleClick = (e) => {
		e.preventDefault();
		setDisabled(true);
		onClick && onClick(e);
		setTimeout(() => {
			setDisabled(false), 200;
		});
	};

	return (
		<button
			onClick={onClick && handleClick}
			className={`${styles.button} ${variant ? styles[variant] : ''}
      ${hover ? styles[hover] : ''}
      ${color ? styles[color] : ''}
      ${size ? styles[size] : ''} ${fullWidth ? styles.fullWidth : ''} ${
			size === 'xs' ? 'ltbody_sm__regular' : 'ltbody_lg__regular'
		}`}
			disabled={disable || disabled}>
			{iconLeft && iconLeft} {children} {iconRight && iconRight}
		</button>
	);
};

export default Button;
